<template>
  <div>
    <Header title="MyTask" :isEditable="false" />
    <!-- My task navbar -->
    <nav
      class="text-black-50 bg-white-100 text-sm px-3 top-16 fixed w-full"
      style="z-index: 9"
    >
      <div
        class="border-b border-black-200 py-2 flex justify-between items-center"
      >
        <!-- Tabs  -->
        <ul
          class="ptabs flex flex-wrap -mb-px text-sm font-medium text-center"
          id="myTab"
          role="tablist"
        >
          <li class="px-3 py-2">
            <router-link :to="{ name: 'MyTaskDetail' }" exact>
              <i class="fas fa-list text-xs"></i> List
            </router-link>
          </li>
        
          <li class="px-3 py-2">
            <router-link :to="{ name: 'myTaskCalendar' }">
              <i class="far fa-calendar text-xs"></i> Calendar
            </router-link>
          </li>
         
        </ul>       
      </div>
    </nav>
    <!-- changing views on tab click -->
    <router-view
      default="{path:'/'}"
      class="pt-28"
      :key="$route.name"
    ></router-view>
  </div>
</template>

<script>
import Header from "../Header.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "MyTask",
  components: {
    Header,
  },
  methods: {
    ...mapMutations(["toggleLoading"]),
  },
  computed: {
    ...mapGetters(["myAbilities"]),
  },
};
</script>